<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1Z"
      fill="#E7B5B5"
    />
    <path
      d="M9.70909 8.29289C9.31856 7.90237 8.6854 7.90237 8.29488 8.29289C7.90435 8.68342 7.90435 9.31658 8.29488 9.70711L10.5868 11.999L8.29485
 14.2909C7.90432 14.6815 7.90432 15.3146 8.29485 15.7052C8.68537 16.0957 9.31854 16.0957 9.70906 15.7052L12.001 13.4132L14.2924 15.7047C14.683
  16.0952 15.3161 16.0952 15.7066 15.7047C16.0972 15.3141 16.0972 14.681 15.7066 14.2904L13.4152 11.999L15.7066 9.7076C16.0971 9.31708 16.0971
   8.68391 15.7066 8.29339C15.3161 7.90286 14.6829 7.90286 14.2924 8.29339L12.001 10.5848L9.70909 8.29289Z"
      fill="#AD0000"
    />
  </svg>
</template>
